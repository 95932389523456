@media (min-width: 800px) and (max-width: 1232px) {
  #adp800 {
    display: block;
  }
  .wrapper {
    display: block;
    width: auto;
    max-width: wrapper_width;
    margin: 0 16px;
  }
  .header .line-2 .logo {
    width: 266px;
  }
  .header .line-2 .top-menu ul li {
    margin-left: 16px;
  }
  .header .line-2 .top-menu ul li a {
    font-family: UbuntuRegular;
    font-size: 14px;
  }
  .slides {
    margin: 16px 0 42px 0;
  }
  .slides .slide .info {
    padding: 16px 2%;
    width: 96%;
    border-radius: 0;
  }
  .slides .slide .info .wrapper {
    margin: 0;
  }
  .slides .slide .info h3 {
    font-size: 16px;
    line-height: 18px;
  }
  .slides .slide .info p {
    font-family: UbuntuRegular;
    font-size: 16px;
    line-height: 1.2em;
  }
  .mainpage-text {
    line-height: 24px;
  }
  .mainpage-text h1 {
    font-family: UbuntuMedium;
    font-size: 20px;
    color: color_black;
    text-transform: none;
  }
  .mainpage-news .slide .info .wrapper {
    margin: 0;
  }
  footer .container {
    flex-wrap: wrap;
  }
  footer .container .block {
    width: 30%;
  }
  footer .container .subscribe {
    width: 30%;
  }
  footer .container .empty {
    display: none;
  }
  footer .container .payment {
    order: 10;
  }
  .catalog-list-container .item {
    display: block;
    width: 42%;
    padding: 16px 2%;
    border-radius: 2px;
    margin-bottom: 32px;
    box-shadow: 0 0 0 2px #eee;
  }
  .catalog-list-container .item .image {
    display: block;
    height: 210px;
    overflow: hidden;
  }
  .catalog-list-container .item .image img {
    display: block;
    width: 100%;
    height: auto;
  }
  .catalog-list-container .item .title {
    margin: 8px 0;
  }
  .catalog-list-container .item .title a {
    font-family: UbuntuRegular;
    line-height: 20px;
    color: color_black;
    font-size: 14px;
  }
  .catalog-list-container .item .price {
    display: block;
    margin: 8px 0;
  }
  .catalog-list-container .item .price .num {
    display: inline;
    font-family: UbuntuMedium;
    color: color_black;
    font-size: 24px;
  }
  .catalog-list-container .item .price .currency {
    display: inline;
    font-family: UbuntuRegular;
    color: color_black;
    font-size: 14px;
  }
  .catalog-list-container .item .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .catalog-list-container .item .buttons .add2basket {
    display: block;
    background-color: color_orange;
    border: 0;
    color: color_white;
    padding: 16px;
    width: 70%;
    font-size: 14px;
    font-family: UbuntuRegular;
    border-radius: 2px;
    cursor: pointer;
  }
  .catalog-list-container .item .buttons .add2basket .buttonLabel2 {
    display: none;
  }
  .catalog-list-container .item .buttons .add2basket.added {
    background-color: rgba(249,105,14,0.6);
  }
  .catalog-list-container .item .buttons .add2basket.added .buttonLabel1 {
    display: none;
  }
  .catalog-list-container .item .buttons .add2basket.added .buttonLabel2 {
    display: inline;
  }
  .catalog-list-container .item .buttons .add2basket .notice {
    position: absolute;
    z-index: 100;
    opacity: 0;
    height: 0;
    width: 0;
    overflow: 0;
    padding: 0;
    margin: 0;
    transition: opacity 0.5s ease-out;
  }
  .catalog-list-container .item .buttons .add2basket .notice.show {
    display: block;
    width: 196px;
    margin-left: -16px;
    margin-top: 22px;
    height: auto;
    opacity: 1;
  }
  .catalog-list-container .item .buttons .add2basket .notice::before {
    content: ' ';
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom: 6px solid color_orange;
    display: inline-block;
    margin-top: -12px;
    margin-left: 100px;
    float: left;
  }
  .catalog-list-container .item .buttons .add2basket .notice .text {
    display: block;
    background-color: color_orange;
    padding: 8px 0;
    color: color_white;
    border-radius: 2px;
  }
  .catalog-list-container .item .buttons .add2bookmarks {
    display: block;
    background-color: color_blue;
    background-image: url("/templates/basic/assets/img/template/favorites-icon.png");
    background-repeat: no-repeat;
    background-position: center center;
    border: 0;
    padding: 16px 24px;
    font-size: 14px;
    font-family: UbuntuRegular;
    border-radius: 2px;
    cursor: pointer;
  }
  .catalog-list-container .item .buttons .add2bookmarks.added {
    background-color: rgba(0,126,187,0.6);
  }
  .catalog-list-container .item .buttons .add2bookmarks .notice {
    position: absolute;
    z-index: 100;
    opacity: 0;
    height: 0;
    width: 0;
    overflow: 0;
    padding: 0;
    margin: 0;
    transition: opacity 0.5s ease-out;
  }
  .catalog-list-container .item .buttons .add2bookmarks .notice.show {
    display: block;
    width: 196px;
    margin-left: -186px;
    margin-top: 22px;
    height: auto;
    opacity: 1;
  }
  .catalog-list-container .item .buttons .add2bookmarks .notice::before {
    content: ' ';
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom: 6px solid color_blue;
    display: inline-block;
    float: right;
    margin-top: -12px;
    margin-right: 2px;
  }
  .catalog-list-container .item .buttons .add2bookmarks .notice .text {
    display: block;
    background-color: color_blue;
    padding: 8px 0;
    color: color_white;
    border-radius: 2px;
  }
  .cart-container .cart-item .right .allPrice {
    font-size: 18px;
  }
}
@media (max-width: 799px) {
  body {
    min-width: 0 !important;
  }
  #adp320 {
    display: block;
  }
  .wrapper {
    width: auto;
    margin: 0 8px;
  }
  .header .container {
    display: block;
  }
  .header .line-1 {
    padding: 0;
    overflow: auto;
  }
  .header .line-1 .left {
    background-position: 2px 0;
    display: block;
    margin: 8px 0;
  }
  .header .line-1 .right {
    display: block;
    white-space: normal;
    font-size: 0;
  }
  .header .line-1 .right .phone,
  .header .line-1 .right .question,
  .header .line-1 .right .languages,
  .header .line-1 .right .bars {
    display: block;
    white-space: normal;
  }
  .header .line-1 .right .phone {
    background-position: 2px 0;
    padding: 0 16px 8px 16px;
  }
  .header .line-1 .right .question {
    display: none;
    background-position: 0 0;
    background-color: transparent;
    padding: 0 16px 8px 20px;
    margin: 0;
  }
  .header .line-1 .right .languages {
    display: inline-block;
    width: 100%;
    margin: 0 -8px;
    padding: 16px 0;
  }
  .header .line-1 .right .languages .lang-list {
    width: 80%;
    padding: 0;
    margin-left: 0px;
  }
  .header .line-1 .right .languages .lang-list >div {
    background-position: 12px center;
  }
  .header .line-1 .right .languages.active .lang-list >div {
    padding: 16px 16px 16px 44px;
  }
  .header .line-1 .right .bars {
    display: inline-block;
    position: absolute;
    margin: 0;
    right: 0;
    background-color: color_orange;
    width: 20%;
    cursor: pointer;
    text-align: center;
    float: right;
  }
  .header .line-1 .right .bars .fa {
    font-size: 18px;
  }
  .header .line-1 .right .bars .top-menu {
    padding: 0 16px;
    margin-left: 0px;
    right: 0;
  }
  .header .line-1 .right .bars .top-menu ul {
    margin: 0;
  }
  .header .line-2 {
    overflow: visible;
    padding: 0;
  }
  .header .line-2 .logo {
    display: block;
    margin: 24px 0;
    margin-right: 0;
  }
  .header .line-2 .right {
    display: block;
    white-space: normal;
  }
  .header .line-2 .right .search-field,
  .header .line-2 .right .search-button,
  .header .line-2 .right .person,
  .header .line-2 .right .basket {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 18px;
    flex: 1;
  }
  .header .line-2 .right .search-field {
    display: block;
    width: 96%;
    margin: 0;
    border: 1px solid color_blue;
    height: 50px;
    padding: 0;
    border-radius: 4px 0 0 4px;
  }
  .header .line-2 .right .search-field input {
    display: inline-block;
    margin-right: 1%;
    text-align: left;
    width: 90% !important;
    margin-left: 2%;
    margin-top: 12px;
    height: 22px;
    border: 0 !important;
    transition: all 0.2s ease-out;
  }
  .header .line-2 .right .search-field input[name="size1"],
  .header .line-2 .right .search-field input[name="size2"],
  .header .line-2 .right .search-field input[name="size3"] {
    display: none;
  }
  .header .line-2 .right .search-button {
    position: absolute;
    right: 8px;
    border-radius: 0 4px 4px 0;
    margin: 0;
    height: 52px;
    margin-top: -52px;
  }
  .header .line-2 .right .person {
    margin: 8px 0;
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }
  .header .line-2 .right .person .person-form {
    width: 272px;
    left: 8;
    margin-left: 0px;
  }
  .header .line-2 .right .person .person-form .field {
    width: auto;
  }
  .header .line-2 .right .basket {
    margin: 8px 0;
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }
  .header .line-2 .right .basket .basket-icon {
    text-align: center;
  }
  .header .line-2 .right .basket .basket-icon .count {
    position: static;
    display: inline;
    background-color: transparent;
    color: color_white;
    width: auto;
    height: auto;
    text-align: center;
    line-height: 12px;
    border-radius: 8px;
    font-size: 18px;
    margin-left: 64px;
    margin-top: 0px;
  }
  .header .line-2 .right .basket .basket-form {
    width: 272px;
    margin-left: 0px;
    right: 8px;
  }
  .header .line-2 .right .basket .basket-form .items-list {
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: -16px -16px 0 -16px;
    max-height: 220px;
  }
  .header .line-2 .right .basket .basket-form .items-list .item {
    display: block;
    border-bottom: 1px solid color_font_silver3;
    padding: 8px;
    color: color_black;
    transition: all 0.3s ease-out;
  }
  .header .line-2 .right .basket .basket-form .items-list .item .icon {
    display: none;
  }
  .header .line-2 .right .basket .basket-form .items-list .item >p {
    display: inline;
    float: left;
    vertical-align: top;
    padding: 4px 0px;
    white-space: normal;
  }
  .header .line-2 .right .basket .basket-form .items-list .item:hover {
    background-color: color_silver_light;
  }
  .header .line-2 .right .basket .basket-form .total-price {
    background-color: color_silver_light;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    margin: 16px -16px;
  }
  .header .line-2 .right .basket .basket-form .total-price .text {
    display: block;
    font-family: UbuntuBold;
    color: color_black;
    font-size: 16px;
  }
  .header .line-2 .right .basket .basket-form .total-price .price {
    display: block;
    font-family: UbuntuRegular;
    font-size: 28px;
    color: color_black;
  }
  .header .line-2 .right .basket .basket-form .total-price .price .fa {
    font-size: 24px;
    color: color_font_silver2;
  }
  .header .line-2 .right .basket .basket-form .orange-button {
    display: block;
    padding: 16px;
    font-size: 14px;
    color: color_white;
    background-color: color_orange;
    text-align: center;
  }
  .header .line-2 .right .basket.active .basket-form {
    height: auto;
    overflow: none;
    border-top: 8px solid color_orange;
    padding: 16px;
  }
  .main-block .container {
    display: block;
  }
  .main-block .container .left-column {
    float: none;
    width: auto;
  }
  .main-block .container .right-column {
    margin-left: 0;
  }
  .catalog-menu {
    margin-bottom: 8px;
  }
  .catalog-menu::after {
    content: ' ';
    display: block;
    height: 8px;
    background-color: color_bg;
    margin: 8px -8px 0;
  }
  .catalog-menu .title {
    cursor: pointer;
  }
  .catalog-menu .title::before {
    border-radius: 4px;
  }
  .catalog-menu .catalog {
    display: block;
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: all 0.5s ease-out 0.15s;
  }
  .catalog-menu .catalog .root {
    width: 100%;
  }
  .catalog-menu .catalog .root li {
    width: 100%;
  }
  .catalog-menu .catalog .child.active {
    width: 100%;
  }
  .catalog-menu .catalog .child.active li {
    width: 100%;
  }
  .catalog-menu .catalog ul li::after {
    display: none;
  }
  .catalog-menu .catalog ul li a {
    display: block;
    width: auto !important;
  }
  .catalog-menu.active .title::before {
    border-radius: 4px 4px 0 0;
  }
  .catalog-menu.active .catalog {
    display: block;
    overflow: none;
    height: auto;
    opacity: 1;
  }
  .mainpage-search .title {
    display: none;
  }
  .mainpage-search .search {
    border-radius: 4px;
  }
  .slides {
    display: none;
  }
  footer .hide320 {
    display: none !important;
  }
  footer .container {
    display: block;
    overflow: auto;
  }
  footer .container .block {
    margin: 32px 0;
    width: auto;
  }
  footer .container .subscribe {
    width: auto;
    max-width: 320px;
  }
  footer .container .subscribe .form {
    height: 36px;
  }
  footer .container .subscribe .form .mail-field {
    text-align: center;
  }
  footer .container .subscribe .form .button {
    height: 44px;
    line-height: 40px;
  }
  footer .copyright {
    padding: 8px 0;
  }
  footer .copyright .container .show-desktop {
    display: block;
    padding: 16px 0 16px 24px;
    color: #fff;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfhDBoKHhAv+ZIQAAAAd0lEQVQoz82QoQ2AMBREXwkJEgRD4LoBQyAZAIsgYQgcKzEFQ2BqSKoOUxIEpZb77t/l/7uDBIwmZooI61mMTlqOiKBmQ1IVO69KylIe/iDIgUEuwpZgpNQL98k7o54xVF3QhPWOB8CzPnvrdKN7T2FxYWwq2wMXuhsn8q7LNzEAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTctMTItMjZUMTA6MzA6MTYrMDE6MDCa9XCtAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE3LTEyLTI2VDEwOjMwOjE2KzAxOjAw66jIEQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=");
    background-repeat: no-repeat;
    background-position: left center;
  }
  .login-form .login .container {
    display: block;
  }
  .login-form .login .container .field {
    width: auto;
  }
  .login-form .login .container .field .button {
    margin-top: 16px;
  }
  .login-form .login .container {
    display: block;
  }
  .login-form .login .container .field {
    width: auto;
  }
  .login-form .login .container .field .button {
    margin-top: 16px;
  }
  .one-item .container .image {
    width: 100%;
  }
  .one-item .container .info {
    width: 100%;
  }
  .one-item .container .info .line-2 .properties strong {
    display: block;
  }
  .catalog-list-container {
    display: flex;
    align-items: stretch;
  }
  .catalog-list-container.tabled .col-2,
  .catalog-list-container.tabled .col-3 {
    display: none !important;
  }
  .catalog-list-container .item {
    width: 45% !important;
  }
  .catalog-list-container .item .image {
    display: block;
    height: auto;
    overflow: hidden;
  }
  .catalog-list-container .item .image img {
    height: auto;
    width: auto;
    max-height: 210px;
    max-width: 100%;
    margin: auto;
  }
  .catalog-list-container .item .buttons {
    align-items: stretch;
  }
  .person-info-container .line {
    display: block;
  }
  .person-info-container .line .field {
    width: auto;
  }
  .person-info-container[data-rel="bookmarks"] .catalog-list-container {
    justify-content: space-between;
    align-items: stretch;
  }
  .cart-container .line {
    display: block;
  }
  .cart-container .line .field {
    width: auto;
    display: block;
  }
  .cart-container .cart-item {
    display: block;
    height: 180px;
  }
  .cart-container .cart-item .left {
    margin-top: 16px;
  }
  .cart-container .cart-item .right {
    display: block;
    margin-top: 16px;
  }
  .cart-container .cart-item .right .allPrice {
    text-align: left;
  }
  .cart-container .next-step {
    display: inline-block;
    float: right;
    margin-top: -16px;
    margin-bottom: 16px;
  }
  .cart-container .totalPrice {
    display: block;
    clear: both;
    background-color: color_blue;
    color: color_white;
    padding: 8px 16px;
    text-align: right;
    border-radius: 2px;
    font-size: 18px;
    margin-bottom: 32px;
  }
  .cart-container .totalPrice .num {
    font-size: 24px;
    font-family: UbuntuLight;
  }
  .catalog-subcategories {
    justify-content: space-around;
  }
  .catalog-subcategories .item {
    width: 47%;
    margin-right: 0;
  }
  .category-container {
    display: block;
  }
  .category-container .category-description {
    width: auto;
    padding-top: 16px;
  }
  .category-container .category-slider {
    width: auto;
  }
  .category-container .category-slider-arrow {
    width: 64px;
    height: 64px;
    font-size: 32px;
  }
  .category-container .category-slider-arrow .fa {
    vertical-align: middle;
    line-height: 1em;
    margin-top: 16px;
  }
  .category-container .category-slider-all-images {
    display: none;
  }
  .table-checked .title {
    text-align: center;
  }
  .table-checked .values {
    display: block;
    overflow: auto;
  }
  .table-checked .values .item {
    display: block;
    margin: 24px 16px;
    font-size: 14px;
    background-image: url("../img/template/icon-check.png");
    background-repeat: no-repeat;
    background-position: 0 4px;
    padding-left: 22px;
  }
  .table-blue .title {
    text-align: center;
  }
  .table-blue .row {
    display: block;
  }
  .table-blue .row .cell {
    display: block;
    font-size: 14px;
    padding: 12px 8px;
  }
}
